import React, { useState, useEffect } from 'react';

import logo from './assets/beaker.svg';
import styles from './App.module.scss'

function App() {

  const [count, rollDice] = useState(6);

  const handleClickRollDice = (e) => {

    fetch("/api/rolld6")
      .then(res => res.json())
      .then(
        (result) => {
          rollDice(result);
        },
        (error) => {
          rollDice(6)
        }
      )
  }

  return (
    <>
      <header className={styles["page-header"]}>
          <h1>Gimpan Laboratory</h1>
      </header>

      <main>
        <section>
          <h2 className={styles["center"]}>
            Let's get started
          </h2>
          <p className={styles["center"]}>Took some time to get this started...</p>
          
          <div className={styles["dicegame"]}>
            <div className={[styles["tray"], styles["tray--wood"]].join(' ')}>
              <div className={styles["dice"]}>
                {count}
              </div>
            </div>
            <button className={styles["btn-roll"]} 
              onClick={handleClickRollDice} >Roll d6</button>
          </div>

  <h2>Draft</h2>
  <iframe src="https://draft.resursbank.se/gront-privatlan/" frameborder="0" allowfullscreen width={400} height={400}></iframe>

  <h2>Live</h2>
  <iframe src="https://resursbank.se/gront-privatlan/" frameborder="0" allowfullscreen width={400} height={400}></iframe>

        </section>
      </main>
      <footer className={styles["page-footer"]}>
        <p>@2022 Gimpan</p>
      </footer>
    </>
  );
}

export default App;
